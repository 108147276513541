<template>
  <div class="links-component">
    <h3>
      {{ $t("showCourse.resources.linksHead") }}
    </h3>
    <div class="links">
      <div v-for="link in linksData" :key="link.id" class="link">
        <a target="_blank" :href="link.path">
          <span> {{ link.description }}</span>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LinksComponent",
  props: {
    linksData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      addIcon: require("@/assets/images/icons/addIcon.svg"),
      modalEdit: false,
      modalData: {},
    };
  },
  computed: {
    rtl() {
      return this.$i18n.locale === "ar";
    },
  },
};
</script>
<style lang="scss">
@import "./_index";
</style>
