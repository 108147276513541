<template>
  <div class="show-course-header">
    <div class="show-course-header__card row">
      <b-col md="8" class="d-flex justify-content-start">
        <div class="show-course-header__card-course">
          <div class="course-content">
            <h4 class="course-content__name">{{ courseData.name }}</h4>
            <div class="course-content__grade">
              <h5 class="title">{{ $t("g.classroom.grade") }}</h5>
              <span class="text"
                >:{{
                  courseData.edu_years && courseData.edu_years.length > 0
                    ? courseData.edu_years[0].name
                    : $t("g.summer_course")
                }}</span
              >
            </div>
          </div>
        </div>
      </b-col>
      <b-col v-if="currentTap === 4" cols="2">
        <div class="export">
          <a
            target="_blank"
            :href="`${baseUrl}web/export/pdf/${this.$route.params.id}`"
            class="pdf"
          >
            <img class="small-ico" :src="pdfIco" alt="pdf" />
            <p>
              {{ $t("export") }}
            </p>
          </a>
          <a
            target="_blank"
            :href="`${baseUrl}web/export/excel/${this.$route.params.id}`"
            class="excel"
          >
            <img class="small-ico" :src="excalIco" alt="pdf" />
            <p>
              {{ $t("export") }}
            </p>
          </a>
        </div>
      </b-col>
    </div>
  </div>
</template>

<script>
import { BRow, BCol, BLink } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
  },
  props: {
    courseData: {
      type: Object,
      default: {},
    },
    currentTap: {
      required: true,
    },
  },
  computed: {
    baseUrl() {
      return process.env.VUE_APP_BASE_URL;
    },
  },
  data() {
    return {
      eduYearExists: false,
      pdfIco: require("@/assets/images/icons/pdf.svg"),
      excalIco: require("@/assets/images/icons/xls.svg"),
    };
  },
  created() {
    this.eduYearExists =
      ![undefined, null].includes(this.courseData.edu_years) &&
      this.courseData.edu_years.length > 0;
  },
};
</script>

<style scoped lang="scss" src="./_index.scss" />
