<template>
  <div class="description-component">
    <h3>
      {{ $t("showCourse.resources.descriptionHead") }}
    </h3>
    <div v-for="descriptionItem in descriptionData" class="description-row">
      <p v-html="descriptionItem.description"></p>
    </div>
    <description-modal
      :data="modalData"
      @update-description="$emit('update-data')"
      :is-edit="modalEdit"
    />
  </div>
</template>
<script>
import { BDropdown, BDropdownItem } from "bootstrap-vue";
import DescriptionModal from "@/components/classrooms/resourcesComponent/Modals/DescriptionModal/index.vue";
export default {
  name: "DescriptionComponent",
  props: {
    descriptionData: {
      type: Array,
      required: true,
    },
  },
  components: { BDropdown, BDropdownItem, DescriptionModal },
  computed: {
    rtl() {
      return this.$i18n.locale === "ar";
    },
  },
  data() {
    return {
      addIcon: require("@/assets/images/icons/addIcon.svg"),
      modalEdit: false,
      modalData: {},
    };
  },
};
</script>
<style lang="scss">
@import "./_index";
</style>
