<template>
  <div class="files-component">
    <h3>
      {{ $t("showCourse.resources.filesHead") }}
      <b-spinner small v-if="isLoading" />
    </h3>
    <div v-for="file in filesData" class="file">
      <div class="info">
        <img :src="icons.docs" alt="mimeTypeIcon" />
        <a :href="file.path" target="_blank"> {{ file.description }} </a>
      </div>
    </div>
  </div>
</template>
<script>
import { BDropdown, BDropdownItem, BSpinner } from "bootstrap-vue";

export default {
  props: {
    filesData: {
      type: Array,
      required: true,
    },
  },

  name: "FilesComponent",
  components: { BDropdownItem, BDropdown, BSpinner },
  computed: {
    rtl() {
      return this.$i18n.locale === "ar";
    },
  },
  data() {
    return {
      isLoading: false,
      addIcon: require("@/assets/images/icons/addIcon.svg"),
      icons: {
        pdf: require("@/assets/images/icons/pdf.png"),
        spreadSheet: require("@/assets/images/icons/xls.png"),
        docs: require("@/assets/images/icons/docx.png"),
      },
    };
  },
};
</script>
<style lang="scss">
@import "./_index.scss";
</style>
