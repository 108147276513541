<template>
  <div class="videos-component">
    <h3>
      {{ $t("showCourse.resources.videosHead") }}
      <b-spinner small v-if="isLoading" />
    </h3>
    <div class="videos row px-1">
      <a v-for="video in videosData" class="video" :key="video.id">
        <div class="div"></div>
        <feather-icon
          @click="openVideoPreview(video.path, video.type)"
          icon="PlayCircleIcon"
          class="play-icon"
      /></a>
    </div>

    <video-preview-component
      :path="videoPathToPreview"
      :type="videoTypeToPreview"
    />
  </div>
</template>
<script>
import { BFormFile, BSpinner } from "bootstrap-vue";
import VideoPreviewComponent from "@/components/classrooms/resourcesComponent/Modals/VideoPreviewModal/index.vue";

export default {
  name: "VideoComponent",
  components: {
    VideoPreviewComponent,
    BFormFile,
    BSpinner,
  },
  props: {
    videosData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      videoPathToPreview: null,
      videoTypeToPreview: null,
      addIcon: require("@/assets/images/icons/uploadFile.svg"),
      isLoading: false,
      videoIcon: require("@/assets/images/videoIcon.svg"),
    };
  },
  methods: {
    openVideoPreview(path, type) {
      this.videoPathToPreview = path;
      this.videoTypeToPreview = type;
      this.$bvModal.show("video-preview-modal");
    },
  },
};
</script>
<style lang="scss">
@import "./_index.scss";
</style>
