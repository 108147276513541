<template>
  <div class="images-component">
    <h3>
      {{ $t("showCourse.resources.imagesHead") }}
      <b-spinner small v-if="isLoading" />
    </h3>
    <div class="images row px-1">
      <a
        v-for="image in imagesData"
        :href="image.path"
        :key="image.id"
        target="_blank"
        class="img"
      >
        <img :src="image.path" alt="image" />
      </a>
    </div>
  </div>
</template>
<script>
import { BSpinner } from "bootstrap-vue";

export default {
  name: "ImagesComponent",
  components: { BSpinner },
  props: {
    imagesData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      addIcon: require("@/assets/images/icons/uploadFile.svg"),
      isLoading: false,
    };
  },
};
</script>
<style lang="scss">
@import "./_index";
</style>
