<template>
  <div class="card-lesson">
    <div
      role="tab"
      class="collapsed"
      :data-index="lesson.id"
      :data-label="`${lesson.name} lesson data`"
      data-hidden="true"
    >
      <div class="lesson__collapsed">
        <img
          v-if="lesson.media"
          :src="lesson.media[0].path"
          :alt="lesson.name"
          class="lesson__image"
        />
        <img
          v-else
          src="@/assets/images/pages/Rectangle 846.png"
          :alt="lesson.name"
          class="lesson__image"
        />
        <b-link
          :to="{
            name: 'CourseDetails',
            params: { id: course_id, lesson_id: lesson.id },
          }"
          class="lesson__content"
        >
          <h4 class="lesson__week">{{ lesson.name }}</h4>
          <div class="lesson-content__progress">
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                :aria-valuenow="lesson.progress"
                :aria-valuemin="lesson.progress"
                aria-valuemax="100"
                :style="{ width: lesson.progress }"
              />
            </div>
            <div class="progress-text">
              <span class="text">{{ lesson.progress + $t('g.done') }} </span>
            </div>
          </div>
        </b-link>
        <div
          class="collapse-icon"
          role="button"
          tabindex="0"
          @click="handleToggleCollapsed"
          :data-index="lesson.id"
        >
          <feather-icon size="20" icon="ChevronDownIcon" color="#000" />
        </div>
      </div>
      <div class="collapse-show">
        <div class="collapse__container">
          <h5 class="des-title">{{ $t('g.description') }}:</h5>
          <p class="des-lesson">
            {{
              lesson.description
                ? lesson.description
                : $t("g.ThereIsNoDescription")
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BLink } from "bootstrap-vue";
export default {
  components: {
    BLink,
  },
  props: ["lesson", "course_id"],
  methods: {
    handleToggleCollapsed(e) {
      const collapsedCards = document.querySelectorAll(
        ".card-lesson .collapsed"
      );
      console.log(e);
      const activeClassName = "collapsed--active";
      const targetIndex = e.target.dataset.index;
      collapsedCards.forEach((card) => {
        const isHidden = card.dataset.hidden === "true";
        const isTarget = card.dataset.index === targetIndex;
        if (card.classList.contains(activeClassName)) {
          card.classList.remove(activeClassName);
          card.dataset.hidden = true;
        }
        if (isHidden && isTarget) {
          card.classList.add(activeClassName);
          card.dataset.hidden = false;
        } else {
          card.classList.remove(activeClassName);
          card.dataset.hidden = true;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss" src="./_index.scss"></style>
